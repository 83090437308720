<template>
  <v-btn class="mb-1 mr-1" elevation="0" rounded @click="emitClick">
    <v-icon>{{ icon }}</v-icon>
    <!-- <v-icon :left="!isMobile">{{ icon }}</v-icon> -->
    <!-- <span v-if="!isMobile">{{ label }}</span> -->
  </v-btn>
</template>

<script>
export default {
  name: "default-button",
  props: {
    icon: String,
    label: String,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    emitClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style>
</style>
