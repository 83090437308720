<!--

  Descrição:
  Simula um label de erro semelhante aos existentes nos formulários.

  
  <error-label :message="errors.message"></error-label>


-->
<template>
  <v-row>
    <v-col>
      <div class="v-text-field__details" :class="{'error-message': message}">
        <div class="v-messages theme--light error--text" role="alert">
          <div class="v-messages__wrapper">
            <div class="v-messages__message">{{message}}</div>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "error-label",
  props: {
    message: { type: String, default: "" }
  }
};
</script>

<style scoped>
.error-message {
  /* animation: v-shake 0.6s cubic-bezier(0.25, 0.8, 0.5, 1); */
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: v-shake;
}
</style>