<template>
  <div class="image-container">
    <div
      style="position: relative"
      :style="`width:${computedWidth}px;height:${computedHeight}px`"
    >
      <croppa
        accept="image/*"
        class="croppa"
        placeholder
        canvas-color="transparent"
        :placeholder-font-size="18"
        :show-remove-button="false"
        remove-button-color="grey"
        :remove-button-size="24"
        :prevent-white-space="true"
        :initial-image="initialImageUrl"
        :width="computedWidth"
        :height="computedHeight"
        :quality="quality"
        v-model="picture"
        @new-image-drawn="onChangeImage"
        @image-remove="onChangeImage"
        @draw="onDraw"
      ></croppa>
    </div>
  </div>
</template>

<script>
// import "./canvasPolyfill";
// import "./canvasToBlob";
// Vue Croppa
import Vue from "vue";
import "vue-croppa/dist/vue-croppa.css";
import Croppa from "vue-croppa";
Vue.use(Croppa);

export default {
  //
  components: {},

  props: {
    value: String,
    width: Number,
    height: Number
  },

  //
  data: () => ({
    // Objeto do vue-croppa contendo tudo
    picture: {},
    // Imagem que é usada dentro do vue-croppa
    initialImageUrl: null,
    // URL da imagem de moldura
    frameUrl: null,
    // Dimensões
    quality: 2,
    noSticker: true,
    timeOut: null
    //
  }),

  //
  computed: {
    computedWidth() {
      if (this.width) return this.width;
      return this.size;
    },
    computedHeight() {
      if (this.height) return this.height;
      return this.size;
    },
    size() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.quality = 1080 / this.$vuetify.breakpoint.width;
        return this.$vuetify.breakpoint.width - 24;
      }
      this.quality = 2;
      return 540;
    }
  },

  //
  methods: {
    /**
     * Define a imagem via URL
     */
    setByURL(url) {
      if (url) {
        // console.log("entrou a url inicial");
        this.initialImageUrl = url;
        this.picture.refresh();
      }
    },

    /**
     * Tem imagem selecionada?
     */
    hasImage() {
      return this.picture.hasImage();
    },

    /**
     * Recebe uma ação iniciada pelos botões de edição e que
     * será realizada pelo vue-croppa na imagem selecionada
     */
    editButtonsClick(actionName) {
      // Se tiver alguma imagem selecionada
      if (!this.picture.hasImage()) {
        this.$emit("message", "Nenhuma imagem selecionada");
        return;
      }

      // Seleciona
      switch (actionName) {
        case "rotate-right":
          this.picture.rotate();
          break;

        case "rotate-left":
          this.picture.rotate(-1);
          break;

        case "flip-x":
          this.picture.flipX();
          break;

        case "flip-y":
          this.picture.flipY();
          break;

        default:
          break;
      }
    },

    /*
     * Força a abertura do selecionador de arquivos do vue-croppa
     */
    croppaClick() {
      if (this.picture) this.picture.chooseFile();
    },

    /**
     * Recebe a imagem como um frame do vídeo, devendo ser
     * convertido para imagem e inserido no visualizador do
     * vue-croppa
     */
    setPicture(video) {
      console.log("setpicture");
      var canvas = document.createElement("CANVAS");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);

      this.initialImageUrl = canvas.toDataURL();

      this.picture.refresh();
      this.$emit("selected", this.initialImageUrl);
    },

    /**
     * Recebe a imagem via vue croppa como base64
     * e insere nele mesmo
     */
    setPictureFromBase64(base64) {
      // console.log("entrou em  setPictureFromBase64");
      this.initialImageUrl = "data:image/png;base64," + base64;
      this.picture.refresh();
      this.$emit("selected", this.initialImageUrl);
    },

    /**
     * Emite o evento ao trocar a imagem dentro do vue-croppa
     */
    onChangeImage() {
      this.$emit("image-changed", this.hasImage());
      this.$emit("selected", this.picture.img);
    },

    addFrame(url) {
      // console.log("tica", url);

      this.frameUrl = url;
      this.picture._draw();
    },

    clearFrame() {
      this.frameUrl = null;
      this.picture._draw();
    },

    onDraw(ctx) {
      if (this.timeOut) clearTimeout(this.timeOut);

      this.timeOut = setTimeout(() => {
        // console.log("ondraw", this.frameUrl);

        if (!this.frameUrl) return;
        const size = this.size * this.quality;

        var image = new Image(size, size);
        image.onload = () => {
          ctx.imageSmoothingEnabled = true;
          ctx.restore();
          ctx.drawImage(image, 0, 0, size, size);
        };
        image.crossOrigin = true;
        image.src = this.frameUrl;
      });

      // ctx.restore();
      // ctx.drawImage(document.querySelector(".addon"), 0, 0, size, size);
      // ctx.save();
    },

    /**
     * Salva a imagem no disco
     *
     */
    saveImage1(size, type, compressionRate) {
      try {
        // Salva a estatística
        this.onDownloadItem();

        this.picture._draw();
        const dataURL = this.picture.canvas.toDataURL("image/png");
        // console.log("ao salvar", dataURL);
        // window.location.href = dataURL;
        var reader = new FileReader();
        var out = new Blob([dataURL], { type: "image/png" });
        reader.onload = function(e) {
          // window.location.href = dataURL;
          var a = document.createElement("a");
          a.download = "imagem_personalizada.png";
          a.href = dataURL;
          a.click();
        };
        reader.readAsDataURL(out);
        return;
      } catch (error) {
        console.log("erro no catch", error);
        throw error;
      }
    },

    getBlob() {
      return new Promise((resolve, reject) => {
        try {
          this.picture._draw();
          const dataURL = this.picture.canvas.toDataURL("image/png");
          const file = this.dataURLtoFile(dataURL, "image.png");
          resolve(file);
        } catch (error) {
          console.log("erro no catch", error);
          // throw error;
          reject(error);
        }
      });
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    saveImageOnChromeIos() {
      try {
        this.picture._draw();
        const dataURL = this.picture.canvas.toDataURL("image/png");
        var reader = new FileReader();
        var out = new Blob([dataURL], { type: "image/png" });
        reader.onload = function(e) {
          window.open(dataURL, "_blank"); // Funcionou: Perguntou se queria abrir popup
        };
        reader.readAsDataURL(out);
      } catch (error) {
        throw error;
      }
    },
    isChromeIos() {
      return navigator.userAgent.match("CriOS");
    },

    saveImage() {
      try {
        if (this.isChromeIos()) {
          this.saveImageOnChromeIos();
        } else {
          this.saveImage1();
        }
      } catch (error) {
        console.log("caiu no erro", error);
        throw error;
      }
    },

    // Remove a imagem atual
    clear() {
      // console.log("clicou em remover a imagem em imagecontainer");
      this.picture.remove();
    },

    onDownloadItem() {
      this.$stats.add("download", {
        module: "customization",
        module_id: null,
        href: this.frameUrl,
        content_type: "image/png"
      });
    }
  }
};
</script>

<style scoped>
.croppa {
  background-color: var(--frame-card);
  /* bordecr: 1px solid var(--frame-primary);
  border:0; */
  /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); */

  box-sizing: border-box;
}

.canvas {
  display: none;
}
</style>
