<template>
  <div>
    <v-row class="wrapper mt-0 mb-1">
      <!-- Foto -->
      <v-col class="pa-0 d-flex justify-center" cols="12">
        <ImageContainer
          @image-changed="onImageChanged"
          @selected="onSelect"
          @message="onCaptureError"
          :width="width"
          :height="height"
          ref="image-container"
        />
      </v-col>
    </v-row>
    <v-row class="my-1">
      <!-- Botões -->
      <v-col class="d-flex justify-center py-1">
        <!-- Selecionar imagem do computador -->
        <default-button
          icon="mdi-folder"
          label="Abrir do computador"
          @click="onCroppaClick"
        />
        <!-- <v-btn large icon @click="onCroppaClick" class="mr-4">
        <v-icon size="36">mdi-folder</v-icon>
      </v-btn>-->

        <!-- Câmera do computador -->
        <CameraButton
          v-if="!isMobile"
          @capture="onCapture"
          @captureError="onCaptureError"
        ></CameraButton>

        <!-- Imagem do arquivo para celulars -->
        <FileButton v-else is-mobile @capture="onCaptureBase64" />
      </v-col>

      <!-- Botões de edição -->
      <v-expand-transition>
        <v-col
          style="width: 100%"
          v-if="hasImage"
          class="d-flex align-center justify-center py-1"
        >
          <EditButtons
            @croppa-image-modify="onEditButtonsClick"
            @message="onCaptureError"
            v-slot="{ rotateRight, rotateLeft, flipX, flipY }"
          >
            <v-btn icon @click="rotateRight">
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
            <v-btn icon @click="rotateLeft">
              <v-icon>mdi-rotate-left</v-icon>
            </v-btn>
            <v-btn icon @click="flipX">
              <v-icon>mdi-flip-horizontal</v-icon>
            </v-btn>
            <v-btn icon @click="flipY">
              <v-icon>mdi-flip-vertical</v-icon>
            </v-btn>
          </EditButtons>
        </v-col>
      </v-expand-transition>
    </v-row>
  </div>
</template>

<script>
import CameraButton from "./CameraButton";
// import CroppaButton from "./CroppaButton";
// import FacebookButton from "./FacebookButton";
import FileButton from "./FileButton";
import EditButtons from "./EditButtons";

import DefaultButton from "./DefaultButton";

import ImageContainer from "./ImageContainer";
// import ImagesList from "./ImagesList";
export default {
  components: {
    CameraButton,
    FileButton,
    EditButtons,
    //
    DefaultButton,
    //
    ImageContainer
  },

  props: {
    value: String,
    width: Number,
    height: Number
  },

  data: () => ({
    error: null,
    hasImage: false,
    menu: false
  }),

  watch: {
    value(url) {
      // if
      // console.log("trocou", url);
      if (url) {
        this.$refs["image-container"].setByURL(url);
      } else {
        this.$refs["image-container"].clear();
      }
    }
  },

  //
  computed: {
    downloadOptions() {
      return [
        {
          text: "Alta resolução (1080 x 1080)",
          callback: () => this.saveImage(1080)
        },
        {
          text: "Média Resolução (640 x 640)",
          callback: () => this.saveImage(640)
        },
        {
          text: "Baixa Resolução (300 x 300)",
          callback: () => this.saveImage(300)
        }
      ];
    },
    isCompatible() {
      return typeof Object["__defineSetter__"] === "function";
    },

    //
    isMobile() {
      return /ipad|iphone|ipod|android|ios/i.test(navigator.userAgent);
    },

    isBrowser() {
      return !this.isMobile;
    },
    userAgent() {
      return navigator.userAgent;
    }
  },

  methods: {
    async getBlob() {
      const file = await this.$refs["image-container"].getBlob();
      return file;
    },

    onCapture(video) {
      // console.log("dentro do index", video);
      this.$refs["image-container"].setPicture(video);
    },

    //
    onCaptureBase64(base64) {
      console.log("entrou em onCaptureBase64");
      this.$refs["image-container"].setPictureFromBase64(base64);
    },

    //
    onCaptureError(value) {
      this.error =
        value || "Seu dispositivo não tem suporte a esta funcionalidade";
      setTimeout(() => {
        this.error = null;
      }, 2000);
    },

    onCroppaClick() {
      this.$refs["image-container"].croppaClick();
    },

    /**
     * Escuta os eventos recebidos pelo componente EditButtons
     */
    onEditButtonsClick(actionName) {
      this.$refs["image-container"].editButtonsClick(actionName);
    },

    /**
     * Ao alterar a foto
     */
    onImageChanged(hasImage) {
      if (this.$refs["image-container"]) {
        // console.log("hasImage", hasImage);
        this.hasImage = hasImage;
      } else {
        // console.log("nao tm imagem");
      }
    },

    onSelect(img) {
      if (this.hasImage) {
        this.$emit("input", img.src);
      } else {
        // this.$emit('input', null)
      }
      // console.log(img.src);
    }

    // /**
    //  * Ao selecionar uma nova moldura
    //  */
    // onSelectFrameImage(url) {
    //   this.$refs["image-container"].addFrame(url);
    // },

    // /**
    //  * Ao clicar em salvar a imagem
    //  */
    // saveImage(size) {
    //   this.$refs["image-container"].saveImage(size);
    // },
  },

  mounted() {
    // this.onCaptureError();
    //   var compatibleBrowser = typeof Object["__defineSetter__"] === "function";
    //   alert(compatibleBrowser);
    // console.log("this.value", this.value);

    if (this.value) {
      // console.log("mounted", this.value);
      this.$refs["image-container"].setByURL(this.value);
    } else {
      // console.log("nao tem imagem");
      this.$refs["image-container"].clear();
    }
  }
};
</script>

<style scoped>
.title {
  width: 100%;
  text-align: left;
}
</style>
