<template>
  <label for="file">
    <default-button icon="mdi-camera" label="Importar da Câmera do Celular" @click="open" />

    <input
      type="file"
      accept="image/*"
      ref="file"
      :capture="isMobile?'camera':null"
      @change="handleFileSelect"
      style="display:none"
    />
  </label>
</template>

<script>
import DefaultButton from "./DefaultButton";
export default {
  components: { DefaultButton },
  props: {
    isMobile: { type: Boolean, default: false },
    label: { type: String, default: "Abrir do computador" },
    icon: { type: String, default: "mdi-folder-multiple-image" },
  },
  methods: {
    open() {
      this.$refs["file"].click();
    },
    handleFileSelect(e) {
      var files = e.target.files;
      if (files.length < 1) {
        alert("select a file...");
        return;
      }
      var file = files[0];
      var reader = new FileReader();
      reader.onload = this.onFileLoaded;
      reader.readAsDataURL(file);
    },

    onFileLoaded(e) {
      var match = /^data:(.*);base64,(.*)$/.exec(e.target.result);
      if (match == null) {
        throw "Could not parse result"; // should not happen
      }
      var mimeType = match[1];
      var content = match[2];
      // alert(mimeType);
      // alert(content);
      this.$emit("capture", content);
    },
  },
};
</script>

<style>
</style>