<template>
  <div>
    <slot :open="open">
      <default-button icon="mdi-camera" label="Abrir Câmera" @click="open" />
    </slot>

    <v-dialog scrollable width="700" height="500" v-model="opened">
      <v-card :color="backgroundColor">
        <v-card-title :color="backgroundColor" class="headline" primary-title>
          <span>Importar da Câmera</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- Vídeo -->
          <video ref="video" width="640" height="480" autolay></video>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close" :color="color">Cancelar</v-btn>
          <v-btn text :color="color" @click="capture">Capturar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mediaCapture from "./mediaCapture";
import DefaultButton from "./DefaultButton";
export default {
  components: { DefaultButton },
  props: {
    backgroundColor: String,
    color: String,
  },
  //
  data: () => ({
    opened: false,
    stream: null,
    video: null, // Objecto que contém o elemento do vídeo
  }),

  //
  methods: {
    open() {
      if (mediaCapture.isSupported()) {
        this.opened = true;
        setTimeout(() => {
          this.play();
          // }
        }, 200);
      } else {
        this.$emit(
          "captureError",
          "Este navegador não suporta esta funcionalidade"
        );
      }
    },

    close() {
      this.stop();
      this.opened = false;
    },

    capture() {
      try {
        this.$emit("capture", this.video);
        this.close();
      } catch (error) {
        console.log("Erro ao capturar", error);
        this.$emit("captureError", "Erro ao capturar a imagem");
      }
    },

    async play() {
      try {
        console.log("rodou o play");

        this.video = this.$refs["video"];
        this.stream = await mediaCapture.createStream(this.video);
      } catch {
        this.$emit(
          "captureError",
          "Erro ao abrir a webcam. Verifique se a câmera está aberta em outro navegador"
        );
      }
    },

    stop() {
      try {
        if (this.stream) {
          console.log("tentando apagar o stream");
          this.stream = mediaCapture.removeStream(this.stream);
          this.video = null;
          // this.stream = null;
        } else {
          console.log(this.stream);
        }
      } catch (error) {
        console.log("erro ao apagar o stream", error);
        this.$emit("captureError");
      }
    },
  },
};
</script>

<style>
</style>
