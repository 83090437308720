
export const support = () => {
  // return !!getUserMedia();

}

export const createStream = (videoObject) => {
  try {

    let streamObject;

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log("default");

      return navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(stream => {
          // console.log()
          streamObject = stream;
          videoObject.srcObject = stream;
          videoObject.play();
          return stream
        })
        .catch(error => {
          console.log("catch 1: " + error.message);
          throw error.message
        });
    }

    // Legacy code below: getUserMedia
    else if (navigator.getUserMedia) {
      // Standard
      console.log("Standard");

      navigator.getUserMedia(
        { video: true },
        stream => {
          streamObject = stream;
          videoObject.src = stream;
          videoObject.play();
        },
        errBack
      );
    } else if (navigator.webkitGetUserMedia) {
      // WebKit-prefixed
      console.log("WebKit-prefixed");

      navigator.webkitGetUserMedia(
        { video: true },
        stream => {
          streamObject = stream;
          videoObject.src = window.webkitURL.createObjectURL(stream);
          videoObject.play();
        },
        errBack
      );
    } else if (navigator.mozGetUserMedia) {
      // Mozilla-prefixed
      console.log("Mozilla-prefixed");

      navigator.mozGetUserMedia(
        { video: true },
        stream => {
          streamObject = stream;
          videoObject.srcObject = stream;
          videoObject.play();
        },
        errBack
      );
    } else {
      // this.$emit("captureError");
      throw new Error('Erro desconhecido ao iniciar a câmera')
    }

    return streamObject
  } catch (error) {
    throw new Error(error.message)
  }

}

export const removeStream = streamObject => {
  try {
    if (streamObject) {
      console.log('stream object', streamObject
        .getTracks());

      streamObject
        .getTracks()
        .forEach(track => {
          track.stop()
          console.log('track', track)
        });

      return streamObject
    }
  } catch (e) {
    console.log('Erro ao fechar a stream', e);

    // throw new Error()
  }
}





export const getDevices = async () => {
  // check for mediaDevices.enumerateDevices() support
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    console.log("enumerateDevices() not supported.");
    return [];
  }

  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter(device => device.kind === 'videoinput')
}

export const isSupported = () => {
  return !!(
    (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia
  );
}




export default {
  createStream,
  removeStream,
  getDevices,
  isSupported
}