<template>
  <div class="justify-space-between d-flex">
    <slot :rotateRight="rotateRight" :rotateLeft="rotateLeft" :flipX="flipX" :flipY="flipY">
      <default-button icon="mdi-rotate-right" label="girar" @click="rotateRight" />
      <default-button icon="mdi-rotate-left" label="girar" @click="rotateLeft" />
      <default-button icon="mdi-flip-horizontal" label="Inverter" @click="flipX" />
      <default-button icon="mdi-flip-vertical" label="Inverter" @click="flipY" />
    </slot>
  </div>
</template>

<script>
import DefaultButton from "./DefaultButton";
export default {
  components: { DefaultButton },
  props: {
    disabled: { type: Boolean, default: false },
  },
  methods: {
    /**
     *
     */
    rotateLeft() {
      this.$emit("croppa-image-modify", "rotate-left");
    },

    /**
     *
     */
    rotateRight() {
      this.$emit("croppa-image-modify", "rotate-right");
    },

    /**
     *
     */
    flipX() {
      this.$emit("croppa-image-modify", "flip-x");
    },

    /**
     *
     */
    flipY() {
      this.$emit("croppa-image-modify", "flip-y");
    },
  },
};
</script>

<style scoped>
</style>
